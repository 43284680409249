import React from 'react'

function Footer() {
    return (
        <>
            <div className="grid grid-cols-12 bg-[#26284C] border-t border-[#404380]">
                <div className="col-span-12 flex justify-center py-4">
                    <p className='text-white'>© 2024 Total Gaming Hub</p>
                </div>
            </div>
        </>
    )
}

export default Footer