import axios from "axios";
import {
    CONTACT_US_API,
    GAME_DETAILS_API,
    GAME_URL_API,
    GET_ALL_GAME_API,
    NEW_ALL_GAME_API,
    ONLINE_ALL_GAME_API,
    RANDOM_GAME_API,
} from "./Api";
import Cookies from "js-cookie";

const headers = {
    "Content-type": "application/json",
};

const getAllGameList = (page, search) => {
    return axios.get(`${GET_ALL_GAME_API}?page=${page}&search=${search}`);
}

const newAllGameList = (page, search) => {
    return axios.get(`${NEW_ALL_GAME_API}?page=${page}&search=${search}`);

}

const onlineAllGameList = (page) => {
    return axios.get(`${ONLINE_ALL_GAME_API}?page=${page}`);

}

const getGameDetails = (id) => {
    return axios.get(`${GAME_DETAILS_API}/${id}`);
}

const randomGameList = (page) => {
    return axios.get(`${RANDOM_GAME_API}?page=${page}`);
}

const gameUrlList = (id) => {
    return axios.get(`${GAME_URL_API}/${id}`);
}

const addContactInfo = (param) => {
    return axios.post(CONTACT_US_API, param, {
        headers: headers
    })
}

export {
    getAllGameList,
    newAllGameList,
    onlineAllGameList,
    getGameDetails,
    randomGameList,
    gameUrlList,
    addContactInfo,
}