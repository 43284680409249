import React, { useEffect, useState } from 'react'
import photo from "../../Assets/image/Mask group (2).png";
import time from "../../Assets/image/time.png";
import noBanner from "../../Assets/image/demo banner.png";
import { useNavigate, useParams } from 'react-router-dom';
import { getGameDetails, randomGameList } from '../../Service/ApiServices';
import toast from 'react-hot-toast';
import { IMG_URL } from '../../Service/Api';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import right from "../../Assets/image/arrow-right.png";
import left from "../../Assets/image/arrow-left.png";
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';

function GameDetails() {

    const navigate = useNavigate()

    const { name, id } = useParams()

    const [loader, setLoader] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState("")

    const [gameDetails, setGameDetails] = useState({})

    const [tags, setTags] = useState([])
    const [gameData, setGameData] = useState([])

    useEffect(() => {
        handleDetails()
    }, [id])

    useEffect(() => {
        handleGameInfo()
    }, [pageNo])

    const handleDetails = () => {
        setPageNo(1)
        setLoader(true)
        getGameDetails(id)
            .then((res) => {
                console.log("res >>", res)
                const data = res.data.info
                setGameDetails(data)
                setTags(data.Tags)
                setLoader(false)
            })
            .catch((err) => {
                const errs = err.response.data
                const status = err.response.status
                if (status === 422) {
                    toast.error(errs.errors[0].msg)
                } else {
                    toast.error(errs.Message)
                }
                setLoader(false)
            })
    }

    const handleGameInfo = () => {
        setIsLoader(true)
        randomGameList(pageNo)
            .then((res) => {
                console.log("res >>", res.data.totalPages)
                const data = res.data.info
                setGameData(data)
                setTotalPage(res.data.totalPages)
                setIsLoader(false)
            })
            .catch((err) => {
                const errs = err.response.data
                const status = err.response.status
                if (status === 422) {
                    toast.error(errs.errors[0].msg)
                } else {
                    toast.error(errs.Message)
                }
                setIsLoader(false)
            })
    }

    const renderPaginationButtons = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'bg-[#222341] border-2 border-[#404380] text-white rounded-[10px] w-[40px] h-[40px] mx-2 font-medium text-base' : 'text-white px-3 py-1 mx-2'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPage) {
            setPageNo(page);
        }
    };

    const handleDetail = (gameTitle, gameId) => {
        const formattedTitle = gameTitle
            .toLowerCase()
            .replace(/[^a-z0-9]/g, "")
        navigate(`/games/${formattedTitle}/${gameId}`);
    }

    const handleGamePlay = () => {
        // window.open(`${window.location.origin}/gameplay/${gameDetails.game_id}`, '_blank');
        navigate(`/gameplay/${name}/${id}`)
    }

    return (
        <>
            <Helmet>
                <title>{gameDetails.game_title || "Online Game Play"} | TotalGaming</title>
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center lg:px-0 px-3'>
                <div className='containers'>
                    <div className='grid-cols-12 grid gap-5 mt-[35px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        {
                            loader ?
                                <SkeletonTheme
                                    baseColor="#222341"
                                    highlightColor="#ffffff30"
                                    borderRadius="0.5rem"
                                    duration={1.5}
                                >
                                    <div className="col-span-12">
                                        <div className='w-full h-[400px] bg-cover bg-center rounded-[12px]'>
                                            <Skeleton height={400} className="rounded-[12px]" />
                                        </div>
                                        <div className="bg-[#ffffff10] rounded-[12px] p-[14px] my-[30px] flex items-center">
                                            <Skeleton height={80} width={80} className="mr-3" />
                                            <div className='font-medium'>
                                                <Skeleton height={24} width={200} className="my-2" />
                                                <Skeleton height={20} width={100} className="my-2" />
                                            </div>
                                        </div>
                                        <div className="text-white">
                                            <Skeleton height={20} className="my-[10px]" />
                                            <Skeleton height={20} className="my-[10px]" />
                                            <Skeleton height={20} className="my-[10px]" />
                                        </div>
                                    </div>
                                </SkeletonTheme>
                                :
                                <div className="col-span-12">
                                    <div className='w-full sm:h-[600px] h-[400px] bg-cover bg-center rounded-[12px]' style={{ backgroundImage: `url(${gameDetails.game_banner ? IMG_URL + gameDetails.game_banner : noBanner})` }}>
                                        <div className='w-full sm:h-[600px] h-[400px] bg-[#00000099] flex justify-center items-center rounded-[12px]'>
                                            <button onClick={handleGamePlay} className='game-button'>
                                                <span>Play Game</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-[12px] p-[14px] my-[30px] flex items-center">
                                        <img src={gameDetails.game_img ? IMG_URL + gameDetails.game_img : ""} className='w-[80px] h-[80px] rounded-[8px] mr-3' alt="" />
                                        <div className='font-medium'>
                                            <h1 className='text-xl my-2'>{gameDetails.game_title || "-"}</h1>
                                            <div className='flex items-center my-2'>
                                                <img src={time} className='w-[24px] mr-3' alt="" />
                                                <p className='text-base'>52K</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-white">
                                        <p className='leading-8 my-[10px]'
                                            dangerouslySetInnerHTML={{
                                                __html: gameDetails.description,
                                            }}
                                        >
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center lg:px-0 px-3 my-[20px]'>
                <div className='containers'>
                    <div className='grid-cols-12 grid'>
                        <div className="col-span-12 flex flex-wrap items-center">
                            {
                                tags.map((item, index) => {
                                    return (
                                        <div key={index} className='bg-[#222341] w-fit rounded-full border-2 px-[20px] py-[10px] border-[#404380] mr-5 my-2'>
                                            <h1 className='text-white'>{item.tag || ""}</h1>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center lg:px-0 px-3'>
                <div className='containers'>
                    <div className='grid-cols-12 grid gap-5 mb-[30px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        {
                            isLoader ?
                                <SkeletonTheme
                                    baseColor="#222341"
                                    highlightColor="#ffffff30"
                                    borderRadius="0.5rem"
                                    duration={1.5}
                                >
                                    {Array(12).fill().map((_, index) => (
                                        <div key={index} className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3 xl:col-span-2">
                                            <Skeleton height={190} />
                                            <Skeleton height={20} style={{ marginTop: 10 }} />
                                        </div>
                                    ))}
                                </SkeletonTheme>
                                :
                                <>
                                    {
                                        gameData.length === 0 ?
                                            <div className='col-span-12 h-[60vh] flex justify-center items-center'>
                                                <div>
                                                    <div className='flex justify-center'>
                                                        <img src={console} className='w-20' alt="" />
                                                    </div>
                                                    <p className='text-lg mt-3 text-[#8c8dab] font-medium'>No Data Found</p>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {gameData.map((item, index) => {
                                                    return (
                                                        <div key={index} onClick={() => handleDetail(item.game_title, item.game_id)} className="hover:scale-105 duration-200 cursor-pointer 4xl:col-span-2 2xl:col-span-2 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-4 col-span-6">
                                                            <div className='flex justify-center'>
                                                                <img src={item.game_img ? IMG_URL + item.game_img : ""} className='sm:h-[190px] sm:w-[190px] w-[150px] h-[150px] rounded-[20px] object-cover' alt="" />
                                                            </div>
                                                            <h1 className='text-white sm:text-lg text-base font-medium text-center mt-3'>{item.game_title || "-"}</h1>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                    }
                                </>
                        }
                    </div>

                    {
                        gameData.length === 0 ? ""
                            :
                            <div className='grid grid-cols-12 my-[30px] bg-[#222341] border-2 border-[#404380] p-[13px] rounded-[12px]'>
                                <div className='col-span-12 flex justify-center items-center'>
                                    <div className='flex items-center cursor-pointer'>
                                        <p onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                                            <img src={left} className='w-[24px] h-[24px]' alt="" />
                                        </p>
                                        <div className="flex items-center mx-4">
                                            {renderPaginationButtons()}
                                        </div>
                                        <p onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                                            <img src={right} className='w-[24px] h-[24px]' alt="" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default GameDetails