import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD0Q2sJS7aRIuLE5MJbnfqD1n5sr68JoVM",
    authDomain: "tgh-total-gaming-hub.firebaseapp.com",
    projectId: "tgh-total-gaming-hub",
    storageBucket: "tgh-total-gaming-hub.appspot.com",
    messagingSenderId: "866413959329",
    appId: "1:866413959329:web:da054555e373b83fd038e0",
    measurementId: "G-06T36Q2L01"
};

const app = initializeApp(firebaseConfig);
// Initialize Analytics
const analytics = getAnalytics(app);

export { analytics, logEvent };

