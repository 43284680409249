import { useEffect } from 'react';
import './App.css';
import Router from './Router/Router';
import { analytics, logEvent } from "./Firebase";

function App() {

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view");
    }
  }, []);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
