import React from 'react';
import Footer from '../Footer/Footer';
import SideBar from '../SideBar/SideBar';

const MainLayouts = ({ children }) => {
    return (
        <div>
            <SideBar />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default MainLayouts;
